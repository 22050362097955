import React, { useEffect, useState } from 'react';
import {useLocation} from 'wouter';
import {VideoType} from 'types/VideoType';
import "./Artists.css";

import * as db from 'db';

const Artists: React.FC = () => {
  const [location, setLocation] = useLocation();
  const [videos, setVideos] = useState<VideoType[]>();

  useEffect(() => {
    return db.getVideosListener((snapshot) => {
      const fetchedVideos: VideoType[] = [];
      snapshot.docs.forEach(doc => {
        const video = doc.data();
        fetchedVideos.push({id: doc.id, ...video} as VideoType);
      })
      console.debug(fetchedVideos);
      setVideos(fetchedVideos);
    },
    (error) => console.error(error))
  }, [])
  return (
    <div className="main">
      <h1>Artister 2021</h1>
      <p>
        Følgende ensembler fremførte sine stykker i Holemkollen Kapell 5. og 6. mars 2021.
      </p>
      <div className="videos">
        {videos ?
        videos.map((video) =>
          <div key={video.id} className="video">
            <iframe title={video.song} width="560" height="315" src={"https://www.youtube.com/embed/" + video.url} frameBorder="0" allowFullScreen></iframe>
            <h3><strong>{video.song}</strong></h3>
            <p>Fremført av {video.group}</p>
          </div>
        )
        :
        ""}
      </div>
    </div>
  )
}

export default Artists;