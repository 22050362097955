import React, { useState } from 'react';
import './App.css';

import { unstable_createMuiStrictModeTheme as createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';

import FrostedGlass from 'containers/FrostedGlass';
import Artists from 'pages/Artists';
import About from 'pages/About';
import Information from 'pages/Information';
import Apply from 'pages/Apply';
import MeetUp from 'pages/MeetUp';
import Contact from 'pages/Contact';
import Diplomas from 'pages/Diplomas';
import Diploma from 'pages/Diploma';

import Header from 'containers/Header';
import HeaderLink from 'components/HeaderLink';

import { Route, useLocation } from "wouter";
import Applications from 'pages/Applications';

interface PrivateRouteProps {
  component: any;
  path?: string;
}

const PrivateRoute = ({
  component: Component,
  path,
  ...other
}: PrivateRouteProps) => {
  //const {auth} = useContext(AuthContext);
  //const [match, params] = useRoute(path || "");

  /*if (!auth.signedIn()) {
    console.debug("redirect to login");
    //return <Redirect to="login" />;
    return <LoginContainer />
  }*/
  return <Component {...other}/>;
};

function App() {
  const [location, setLocation] = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  const prefersDarkMode = true; //useMediaQuery('(prefers-color-scheme: dark)'); // only support dark mode for now
  const smallScreen = useMediaQuery('(max-width:600px)');

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: prefersDarkMode ? 'dark' : 'light',
          primary: {
            main: 'rgb(187, 215, 249)',
            light: 'rgb(187, 215, 249)',
            dark: 'rgb(187, 215, 249)',
          },
          secondary: {
            main: '#012C6B',
          },
        },
      }),
    [prefersDarkMode],
  );

  const header = (
    <Header>
      <HeaderLink href="/" onClick={() => setShowMenu(false)}>Om festivalen</HeaderLink>
      <HeaderLink href="/artists" onClick={() => setShowMenu(false)}>Artister 2021</HeaderLink>
      <HeaderLink href="/info" onClick={() => setShowMenu(false)}>Praktiske opplysninger</HeaderLink>
      <HeaderLink href="/paamelding" onClick={() => setShowMenu(false)}>Påmelding</HeaderLink>
      <HeaderLink href="/oppmote" onClick={() => setShowMenu(false)}>Oppmøte</HeaderLink>
      <HeaderLink href="/kontakt" onClick={() => setShowMenu(false)}>Kontakt info</HeaderLink>
    </Header>
  )
  const currentPage = location.split('/', 2)[1];

  return (
    <ThemeProvider theme={theme}>
      <div className={currentPage === 'paamelding' || currentPage === 'oppmote' ? "App inside" : "App"}>
        {smallScreen ?
        <div className="menu">
          <IconButton color="primary" aria-label="menu" component="span" onClick={() => setShowMenu(true)}>
            <MenuIcon />
          </IconButton>
        </div>
        :""}
        {showMenu || !smallScreen ? header : ""}
        <div className={currentPage === 'diplom' ? "container container-full-height":"container"}>
          <FrostedGlass header={null}>
            <Route path="/" component={About} />
            <Route path="/artists" component={Artists} />
            <Route path="/info" component={Information} />
            <Route path="/paamelding" component={Apply} />
            <Route path="/applications" component={Applications} />
            <Route path="/oppmote" component={MeetUp} />
            <Route path="/kontakt" component={Contact} />
            <Route path="/diplomer" component={Diplomas} />
            <Route path="/diplom/:id" component={Diploma} />
          </FrostedGlass>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
