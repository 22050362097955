import React from 'react';
import "./Header.css";

interface Props {
  children: React.ReactNode;
}

const Header: React.FC<Props> = (props: Props) => {
  return (<>
      <div className="header">{props.children}</div>
    </>
  )
}

export default Header;