import React, { useEffect, useState } from 'react';
import "./Diploma.css";
import gsmkLogo from '../assests/gsmk_logo.svg';
import {useRoute} from 'wouter';
import * as db from 'db';
import { VideoType } from 'types/VideoType';
import UnderskriftSergey from 'assests/underskrift_sergey.png';
import UnderskriftAndreas from 'assests/underskrift_andreas.png';
import UnderskriftOystein from 'assests/underskrift_oystein.png';
import UnderskriftGeirJarle from 'assests/underskrift_geir_jarle.png';

const Diplomas: React.FC = () => {
  //const [location, setLocation] = useLocation();
  const [, params] = useRoute("/diplom/:id");
  const [video, setVideo] = useState<VideoType>();

  useEffect(() => {
    //console.debug("fetching videodata", match, params);
    async function fetchData() {
      if(params) {
        const videoData = await db.getVideoData(params.id);
        setVideo(videoData);
      }
    }
    fetchData();
  },[])

  return (
    <div>
      <div className="home-content-diploma">
        <h2>Holmenkollen Ensemblefestival 2021</h2>
        <h1>DIPLOM</h1>
        <h3>tildeles</h3>
        <h2>{video?.group}</h2>
        <h3>for fremføring av</h3>
        <h2>{video?.song}</h2>
        <p>
          "{video?.feedback}"
        </p>
        <div className="signatures">
          <div className="signature"><img src={UnderskriftSergey} alt="Underskrift Sergey"/><br/>SERGEY VICHROV<br/>dommer</div>
          <div className="signature"><img src={UnderskriftGeirJarle} alt="Underskrift Geir Jarle"/><br/>GEIR JARLE BJØRNENG<br/>arrangementsleder</div>
          <div className="signature"><img src={UnderskriftAndreas} alt="Underskrift Andreas"/><br/>ANDREAS SOLEM KJÆR<br/>video/lyd-produksjon</div>
          <div className="signature"><img src={UnderskriftOystein} alt="Underskrift Øystein"/><br/>ØYSTEIN ØKLAND<br/>leder GSMK</div>
        </div>
      </div>
      <div className="home-logo">
        <img src={gsmkLogo} className="gsmk-logo" alt="gsmk logo" />
      </div>
    </div>
  )
}

export default Diplomas;