import React, { useEffect, useState } from 'react';
import {useLocation} from 'wouter';
import {ApplicationType} from 'types/ApplicationType';
import "./Applications.css";

import * as db from 'db';

const Artists: React.FC = () => {
  const [location, setLocation] = useLocation();
  const [applications, setApplications] = useState<ApplicationType[]>();

  useEffect(() => {
    return db.getApplicationsListener(db.year, (snapshot) => {
      const fetchedApplications: ApplicationType[] = [];
      snapshot.docs.forEach(doc => {
        const application = doc.data();
        fetchedApplications.push({id: doc.id, ...application} as ApplicationType);
      })
      console.debug(fetchedApplications);
      setApplications(fetchedApplications);
    },
    (error) => console.error(error))
  }, [])
  return (
    <div className="main">
      <h1>Påmeldinger</h1>
      <div className="applications-title">
        <div>Følgende ensembler har meldt seg på:</div>
        <div>Antall: {applications?.length}</div>
      </div>
      <div className="applications">
        {applications ?
        applications.map((application) =>
          <div key={application.id} className="application">
            <h3><strong>{application.name}</strong></h3>
            <p>{application.description}</p>
            <p><strong>Påmeldt:</strong> {application.regDate.toDate().toLocaleString('nb-NO')}</p>
            <p><strong>Aldersgruppe:</strong> {application.agegroup}</p>
            <p><strong>Antall deltakere:</strong> {application.noContestants}</p>
              <ul>
              {application.contestant.map((contestant, index) =>
                <li key={index}>{contestant}</li>
              )}
              </ul>
            <p><strong>Kontaktperson:</strong> <a href={"mailto:"+application.contactEmail}>{application.contactName}</a> Tlf: {application.contactPhone}</p>
            <p><strong>Kommentar:</strong> {application.comment}</p>
          </div>
        )
        :
        ""}
      </div>
    </div>
  )
}

export default Artists;