import React from 'react';
import "./FrostedGlass.css";

interface Props {
  children: React.ReactNode;
  header: React.ReactNode;
}

const FrostedGlass: React.FC<Props> = (props: Props) => {
  return (<>
      <div className="frosted-main">
        <div className="frosted-header">{props.header}</div>
        <div className="frosted-content">{props.children}</div>
      </div>
      <div className="frosted-backdrop"></div>
    </>
  )
}

export default FrostedGlass;