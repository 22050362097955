// eslint-disable-next-line @typescript-eslint/no-unused-expressions
var firebaseConfig = {
  apiKey: "AIzaSyAz-11cIcapjOKzesFoeX2BsR8KrNvvZRI",
  authDomain: "holmenkollen-ensemblefestival.firebaseapp.com",
  projectId: "holmenkollen-ensemblefestival",
  storageBucket: "holmenkollen-ensemblefestival.appspot.com",
  messagingSenderId: "896721158087",
  appId: "1:896721158087:web:1885d2d838656f6ba723e2",
  measurementId: "G-HFBS80WM6Y"
};

export default firebaseConfig;
