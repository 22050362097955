import React from 'react';
import "./About.css";
import gsmkLogo from '../assests/gsmk_logo.svg';
import {useLocation} from 'wouter';
import {year} from '../db';

const About: React.FC = () => {
  const [location, setLocation] = useLocation();

  return (
    <div className="home-main">
      <div className="home-content">
        <h3>Grindbakken Skoles Musikkorps inviterer til</h3>
        <h1>Holmenkollen Ensemblefestival {year}</h1>
        <p>
          I år som i fjor ønsker Grindbakken skoles musikkorps å invitere til en ekslusiv <strong>festival for barn og unge i ensemble-format</strong>.
        </p>
        <p>
          Med dette kan mange barn og unge få et 
          spennende og motiverende mål å øve fram til.
        </p>
        <p>
          <strong>Dato:</strong> Lørdag 12. februar 2022<br/>
          <strong>Tidspunkt:</strong> 10.00 – 18.00 (eksakt tidspunkt pr.gruppe settes opp senere)<br/>
          <strong>Sted:</strong> Holmenkollen kapell
        </p>
        <div className="buttons">
          <button onClick={() => setLocation("info")}>Les mer...</button>
          <button onClick={() => setLocation("paamelding")}>Til påmelding</button>
        </div>
      </div>
      <div className="home-logo">
        <img src={gsmkLogo} className="gsmk-logo" alt="gsmk logo" />
      </div>
    </div>
  )
}

export default About;