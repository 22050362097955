import React from 'react';

const MeetUp: React.FC = () => {
  return (
    <div className="main">
      <h1>Oppmøte for deltakere</h1>
      <p>
        Vi benytter kjelleren i Holmenkollen kapell til oppvarming, og selve hovedkapellet for innspilling. Dette er et vakkert og særpreget kirkerom som vil gi en positiv og inspirerende ramme for deltakerne. 
      </p>
      <p>
        <strong>Dato:</strong> Lørdag 12.februar 2022
      </p>
      <p>
        <strong>Tidspunkt:</strong> 10.00 – 18.00 (eksakt tidspunkt pr.gruppe settes opp senere)
      </p>
      <p>
        <strong>Sted:</strong> Holmenkollen kapell
      </p>
      <p>
        <strong>Inngang:</strong> Holmenkollen kapell, kjeller (oppvarming)
      </p>
      <p>
        <strong>Utgang:</strong> Hovedinngang hovedkapell  
      </p>
      <p>
        <strong>Gruppeinndeling:</strong> 
        <li>Gruppe 1 (barneskolealder)</li>
        <li>Gruppe 2 (ungdomsskole)</li>
        <li>Gruppe 3 (videregående skole)</li>
        Eldste deltaker angir hvilken klasse ensemblet deltar i. 
      </p>
    </div>
  )
}

export default MeetUp;