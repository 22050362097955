import React from 'react';
import "./HeaderLink.css";
import { useLocation, Link } from "wouter";

interface Props {
  href: string;
  children: React.ReactNode;
  onClick?: () => void;
}

const Header: React.FC<Props> = (props: Props) => {
  const [location, setLocation] = useLocation();
  return (<>
      <Link href={props.href} className={location === props.href ? 'active': ''} onClick={props.onClick}>{props.children}</Link>
    </>
  )
}

export default Header;