import React from 'react';
import './Contact.css';

const Contact: React.FC = () => {
  return (
    <div className="contact-main">
      <h1>Kontaktinformasjon</h1>
      <p>
        <strong>Geir Jarle Bjørneng (arrangementsansvarlig) telefon:</strong> 99267692  
      </p>
      <p>
        <strong>E-post:</strong> <a href="mailto:styret@gsmk.no">styret@gsmk.no</a> 
      </p>
      <p>
        Grindbakken Skoles Musikkorps, Måltrostveien 106, 0786 Oslo 
      </p>
      <p>
        <a href="http://www.gsmk.no">www.gmsk.no</a>  Øystein Økland, styreleder
      </p>
    </div>
  )
}

export default Contact;