import React, {useState} from 'react';
import "./Apply.css";

import {Grid} from '@material-ui/core';
import { FormControl, InputLabel, Input, FormHelperText, TextField, Select, MenuItem } from '@material-ui/core';
import { useForm, Controller } from "react-hook-form";
import { ErrorDisplay } from "components/ErrorDisplay";
import {useLocation} from 'wouter';

import * as db from 'db';

const Apply: React.FC = () => {
  const [noContestants, setNoContestants] = useState(0);
  const [applicationRegistered, setApplicationRegistered] = useState(false);
  const [location, setLocation] = useLocation();

  const { handleSubmit, control, errors } = useForm();

  const saveData = (formData: any) => {
    const storeContestans = formData.contestant;
    if(formData.noContestants < 5) {
      storeContestans.splice(formData.noContestants);
    }
    const data = {...formData, contestant: storeContestans};
    data.year = db.year;
    db.storeNewApplication(data).then( (document) =>
      fetch('https://festival.gsmk.no/api/process_application.php?id=' + document.id)
        .then(response => setApplicationRegistered(true))
    )
  }

  return (
    <div className="main">
      <h2>Påmelding til Holmenkollen Ensemblefestival {db.year}</h2>
      {false ?
      <div className="application-registered">
        <h2>Påmeldingen for {db.year} er nå stengt.</h2>
        <h3>Kom tilbake neste år for å melde deg på</h3>
        <h2><strong>Holmenkollen Ensemblefestival {db.year+1}</strong></h2>
        <div>
          <h3>I mellomtiden kan du ta en titt på tidligere fremføringer</h3>
          <button onClick={() => setLocation("/artists")}>Tidligere fremføringer</button>
        </div>
      </div>
      :
      applicationRegistered ?
      <div className="application-registered">
        <h2>Takk for din påmelding.</h2>
        <h2>Deltakeravgift kr 100 pr. deltaker/musiker Vippses til 14002 med navn på ensemble.</h2>
        <h3>Du vil bli kontaktet når betalingen er registrert.</h3>
      </div>
      :
      <form onSubmit={handleSubmit(saveData)}>
        <Grid container spacing={1}>
          <Grid item xs={12} style={{paddingTop: '2em'}}>
            <span>Om ensemblet:</span>
          </Grid>
          <Grid item xs={12}>
            <FormControl required fullWidth>
              <InputLabel id="ensemble-select-helper-label">Størrelse på emsemblet</InputLabel>
              <Controller
                render={({ onChange, value }) => (
                  <Select
                    labelId="ensemble-select-helper-label"
                    id="ensemble-select-helper"
                    value={value}
                    onChange={(event) => {onChange(event); setNoContestants(event.target.value as number);}}
                  >
                    <MenuItem value={2}>Duett</MenuItem>
                    <MenuItem value={3}>Trio</MenuItem>
                    <MenuItem value={4}>Kvartett</MenuItem>
                    <MenuItem value={5}>Kvintett</MenuItem>
                  </Select>
                )}
                control={control}
                defaultValue=""
                name="noContestants"
                rules={{
                  required: "'Antall deltakere' må fylles ut.",
                }}
              />
              <ErrorDisplay errors={errors} elementName="noContestants" />
            </FormControl>
          </Grid>
          {noContestants > 0 ? Array(noContestants).fill(0).map((value, index) =>
            <React.Fragment key={index}>
              <Grid item xs={1}></Grid>
              <Grid item xs={11}>
                <FormControl required fullWidth>
                  <InputLabel htmlFor="name-input">Deltaker nr {index+1}</InputLabel>
                  <Controller
                    render={({ onChange, value }) => (
                      <Input id={"contestant"+index+"-name-input"} aria-describedby={"contestant"+index+"-name-helper-text"} onChange={onChange} />
                    )}
                    control={control}
                    defaultValue=""
                    name={`contestant[${index}]`}
                    rules={{
                      required: "Du må skrive inn et navn.",
                    }}
                  />
                  <FormHelperText id={"contestant"+index+"-name-helper-text"}>* Må fylles ut</FormHelperText>
                </FormControl>
              </Grid>
            </React.Fragment>
          )
          :""}
          <Grid item xs={12}>
            <FormControl required fullWidth>
              <InputLabel id="agegroup-select-helper-label">Aldersgruppe</InputLabel>
              <Controller
                render={({ onChange, value }) => (
                  <Select
                    labelId="agegroup-select-helper-label"
                    id="agegroup-select-helper"
                    value={value}
                    onChange={onChange}
                  >
                    <MenuItem value="Barneskole">Barneskole</MenuItem>
                    <MenuItem value="Ungdomsskole">Ungdomsskole</MenuItem>
                    <MenuItem value="Videregående">Videregående</MenuItem>
                  </Select>
                )}
                control={control}
                defaultValue=""
                name="agegroup"
                rules={{
                  required: "'Aldersgruppe' må fylles ut.",
                }}
              />
              <ErrorDisplay errors={errors} elementName="agegroup" />
              <FormHelperText>Eldste deltaker angir hvilken klasse ensemblet deltar i</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl required fullWidth>
              <InputLabel htmlFor="name-input">Navn på ensemblet</InputLabel>
              <Controller
                render={({ onChange }) => (
                  <Input id="name-input" aria-describedby="name-helper-text" onChange={onChange} />
                )}
                control={control}
                defaultValue=""
                name="name"
                rules={{
                  required: "Du må skrive inn et navn.",
                }}
              />
              <FormHelperText id="name-helper-text">* Må fylles ut</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl required fullWidth>
              <Controller
                render={({ onChange }) => (
                  <TextField
                    id="description"
                    label="Beskrivelse av ensemble"
                    placeholder="Beskrivelse av ensemble (for publisering på nettsiden)"
                    multiline
                    onChange={onChange}
                  />
              )}
                name="description"
                control={control}
                defaultValue=""
                autoComplete=""
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} style={{paddingTop: '2em'}}>
            <span>Kontaktinfo:</span>
          </Grid>
          <Grid item xs={12}>
            <FormControl required fullWidth>
              <InputLabel htmlFor="contact-name-input">Navn på kontaktperson</InputLabel>
              <Controller
                render={({ onChange }) => (
                  <Input id="contact-name-input" aria-describedby="contact-name-helper-text" onChange={onChange} />
                )}
                control={control}
                defaultValue=""
                name="contactName"
                rules={{
                  required: "Du må skrive inn et navn.",
                }}
              />
              <FormHelperText id="contact-name-helper-text">* Må fylles ut</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl required fullWidth error={false}>
              <InputLabel htmlFor="contact-phone-input">Telefonnummer til kontaktperson</InputLabel>
              <Controller
                render={({ onChange }) => (
                  <Input id="contact-phone-input" type="number" aria-describedby="contact-phone-helper-text" onChange={onChange} />
                )}
                control={control}
                defaultValue=""
                autoComplete=""
                name="contactPhone"
                rules={{
                  required: "'Telefonnummer' må fylles ut.",
                }}
              />
              <FormHelperText id="contact-phone-helper-text">* Må fylles ut</FormHelperText>
              <ErrorDisplay errors={errors} elementName="contactPhone" />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl required fullWidth error={false}>
              <InputLabel htmlFor="contactEmail">E-postadresse til kontaktperson</InputLabel>
              <Controller
                render={({ onChange }) => (
                  <Input id="contact-email-input" type="email" aria-describedby="contact-email-helper-text" onChange={onChange} />
                )}
                control={control}
                defaultValue=""
                autoComplete=""
                name="contactEmail"
                rules={{
                  required: "'E-postadresse' må fylles ut.",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Ugyldig e-postadresse",
                  },
                }}
              />
              <FormHelperText id="contact-email-helper-text">* Må fylles ut</FormHelperText>
              <ErrorDisplay errors={errors} elementName="contactEmail" />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl required fullWidth>
              <Controller
                render={({ onChange }) => (
                  <TextField
                    label="Kommentarer til arrangør"
                    placeholder="Kommentarer og spørsmål til arrangør"
                    multiline
                    onChange={onChange}
                  />
                )}
                name="comment"
                control={control}
                defaultValue=""
                autoComplete=""
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} className="submit-cell">
            <button type="submit">Send inn</button>
          </Grid>
          <Grid item xs={12}>
            <p style={{textAlign: 'center'}}>
              <strong>Deltakeravgift:</strong> kr 100 pr. deltaker/musiker (Vipps til 14002 med navn på ensemble)
            </p>
          </Grid>
        </Grid>
      </form>
      }
    </div>
  )
}

export default Apply;