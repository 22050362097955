import React from 'react';
import {useLocation} from 'wouter';

const Information: React.FC = () => {
  const [location, setLocation] = useLocation();
  return (
    <div className="main">
      <h1>Praktisk informasjon</h1>
      <p>
        Festivalen vil bestå av <strong>konserter som spilles inn på video</strong> i Holmenkollen kapell med publikum. Videoene skal deretter bedømmes og det hele publiseres på hjemmesiden noen uker etter innspilling.
      </p>
      <p>
        Vi vil legge vekt på <strong>konstruktive og positive dommerkommentarer</strong> samt å gi deltakerne en opplevelse av at de er med på noe som er meningsfylt og utviklende. Samtlige deltakere får medalje.
      </p>
      <p>
        Vi benytter kjelleren i Holmenkollen kapell til oppvarming, og selve hovedkapellet for innspilling. Dette er et vakkert og særpreget kirkerom som vil gi en positiv og inspirerende ramme for deltakerne. 
      </p>
      <p>
        Til sammen vil ca. 25-30 ensembler kunne opptre. 
      </p>
      <h1>Sammendrag</h1>
      <p>
        <strong>Dato:</strong> Lørdag 12.februar 2022
      </p>
      <p>
        <strong>Tidspunkt:</strong> 10.00 – 18.00 (eksakt tidspunkt pr.gruppe settes opp senere)
      </p>
      <p>
        <strong>Sted:</strong> Holmenkollen kapell
      </p>
      <p>
        <strong>Deltakeravgift:</strong> kr 100 pr. deltaker/musiker (Vipps til 14002 med navn på ensemble)
      </p>
      <p>
        3-4 ensembler spiller pr. time
      </p>
      <p>
        Programmets spilletid pr gruppe: Inntil 10 minutter
      </p>
      <p>
        <strong>Gruppeinndeling:</strong> 
        <li>Gruppe 1 (barneskolealder)</li>
        <li>Gruppe 2 (ungdomsskole)</li>
        <li>Gruppe 3 (videregående skole)</li>
        Eldste deltaker angir hvilken klasse ensemblet deltar i. 
      </p>
      <p>
        <strong>Partiturer/noter:</strong> Sendes på forhånd til styret@gsmk.no
      </p>
      <p>
        <strong>Frist for innsending av partiturer:</strong> 1.februar 2022
      </p>
      <div className="right-align">
        <button onClick={() => setLocation("paamelding")}>Til påmelding</button>
      </div>
    </div>
  )
}

export default Information;